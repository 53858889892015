<template>
  <div>
    <div class="tab-header">
      <div
        class="tab-btn"
        v-for="(item, index) in [
          '现场检查',
          '文件检查',
          '考核细则',
          '内外审检查',
          '供应商审核'
        ]"
        :key="index"
        @click="clickBtnIndex(index)"
      >
        <el-button :class="{ primary: tabBtnIndex == index }">{{
          item
        }}</el-button>
      </div>
      <div class="text">
        <span>开始时间：</span>
        <el-date-picker
          v-model="startTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择开始日期"
        >
        </el-date-picker>
      </div>
      <div class="text">
        <span>结束时间：</span>
        <el-date-picker
          v-model="endTime"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择结束日期"
        >
        </el-date-picker>
      </div>
      <div class="text">
        <el-button type="primary" icon="el-icon-search" @click="search">搜索</el-button>
      </div>
    </div>

    <div class="_Tab">
      <span
        v-for="(item, index) in tabList"
        :key="index"
        @click="clickTab(index)"
        :class="{ activeColor: index == tabIndex }"
        >{{ item }}</span
      >
    </div>
    <el-button
      v-if="tabIndex == 0"
      style="background-color: #1361ff; margin-bottom: 10px"
      type="primary"
      class="edit"
      @click="allToReport"
      >全部发出报告</el-button
    >
    <div class="_TableBox">
      <el-table
        :data="tableData.slice(start, end)"
        style="width: 100%"
        :stripe="true"
        :height="elementHeight"
      >
        <el-table-column type="selection" width="50"> </el-table-column>
        <el-table-column type="index" label="序号" width="50">
        </el-table-column>
        <el-table-column v-if="tabBtnIndex == 4" prop="checkResult" label="不符合类别" width="100">
          <template slot-scope="scope">
            <span v-if="scope.row.checkResult == '轻微不符合'" style="color:blue">{{ scope.row.checkResult }}</span>
            <span v-else style="color:red">{{ scope.row.checkResult }}</span>
          </template>
        </el-table-column>
        <el-table-column v-else prop="type" label="不符合类别" width="100">
        </el-table-column>
        <el-table-column prop="question" label="问题" :width="tableWidth">
        </el-table-column>
        <el-table-column prop="trackTimes" label="跟进次数" width="80">
        </el-table-column>
        <!-- <el-table-column prop="checkRecord" label="检查记录" width="250">
        </el-table-column> -->
        <!-- <el-table-column prop="checkResult" label="检查结果" width="100">
        </el-table-column> -->
        <!-- <el-table-column prop="violationItemNo" label="违法条数" width="180">
        </el-table-column> -->
        <!-- <el-table-column prop="unqualifiedType" label="不符合类型" width="180">
        </el-table-column> -->
        <template v-if="tabIndex != 0">
          <!-- <el-table-column prop="problemWorkSitName" label="发现岗位">
          </el-table-column> -->
          <el-table-column prop="sendTrackName" label="发送人" width="100">
          </el-table-column>
          <el-table-column prop="sendTrackDate" label="发送日期" width="150">
            <template slot-scope="scope">
              <span>{{ scope.row.sendTrackDate | time }}</span>
            </template>
          </el-table-column>
        </template>
        <!-- <el-table-column prop="fileName" label="文件名" width="250">
        </el-table-column> -->
        <template v-if="tabIndex >= 2">
          <el-table-column
            prop="correctiveMeasures"
            label="纠正措施"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="correctiveDeadlines"
            label="纠正期限"
            width="180"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.correctiveDeadlines | time }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="sendCorrectName"
            label="提交措施人"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="sendCorrectDate"
            label="提交措施日期"
            width="180"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.sendCorrectDate | time }}</span>
            </template>
          </el-table-column>
        </template>
        <template v-if="tabIndex >= 3">
          <el-table-column prop="sendProvenName" label="提交验证人" width="180">
          </el-table-column>
          <el-table-column
            prop="sendProvenDate"
            label="提交验证日期"
            width="180"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.sendProvenDate | time }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="provenContent" label="验证情况" width="180" v-if="tabIndex >= 4">
          </el-table-column>
        </template>
        <template v-if="tabIndex >= 4">
          <el-table-column prop="sureProvenName" label="验证人" width="180">
          </el-table-column>
          <el-table-column prop="sureProvenDate" label="验证日期" width="180">
            <template slot-scope="scope">
              <span>{{ scope.row.sureProvenDate | time }}</span>
            </template>
          </el-table-column>
          <el-table-column label="是否通过" width="180">
            <!-- 0未通过，1通过 -->
            <span slot-scope="scope">{{
              scope.row.isPass == 1 ? "是" : "否"
            }}</span>
          </el-table-column>
          <el-table-column label="现场证据" width="180">
            <template slot-scope="scope">
              <el-button size="small" @click="clickImageBtn(scope.row)"
                >共 {{ scope.row.picCount }} 张</el-button
              >
            </template>
          </el-table-column>
        </template>
        <el-table-column prop="problemWorkSitName" label="操作" v-if="tabIndex != 4">
          <template slot-scope="scope">
            <el-button
              v-if="tabIndex == 0"
              type="primary"
              style="border-radius: 50px !important"
              @click="toReport(scope.row)"
              >发出报告</el-button
            >
            <el-button
              v-if="tabIndex == 1"
              type="primary"
              style="border-radius: 50px !important"
              @click="submitMeasure(scope.row)"
              >提交措施</el-button
            >
            <el-button
              v-if="tabIndex == 2"
              type="primary"
              style="border-radius: 50px !important"
              @click="reqExamine(scope.row)"
              >提交验证</el-button
            >
            <el-button
              v-if="tabIndex == 3"
              type="primary"
              style="border-radius: 50px !important"
              @click="confirmExamine(scope.row)"
              >确定验证</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[10, 20, 50]"
        :page-size="curSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.length"
        class="_Pagination"
      >
      </el-pagination>
    </div>
    <el-dialog
      title="修改报告"
      :visible.sync="dialogVisible"
      width="25%"
      style="margin-top: 50px"
      >
      <div class="unqualified">
        <span>不合格描述：</span>
        <el-input type="textarea" v-model="describe" :rows="5"></el-input>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="reportReq">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="提交措施"
      :visible.sync="dialogVisible2"
      width="25%"
      style="margin-top: 50px"
      >
      <div class="measures-dialog">
        <div style="display: flex">
          <span class="span" style="margin-bottom: 5px">纠正措施：</span>
          <el-input
            style="width: 80%"
            v-model="measures"
            type="textarea"
            :rows="3"
            placeholder="请输入"
          ></el-input>
        </div>
        <div style="display: flex; margin-top: 10px">
          <span class="span">纠正期限：</span>
          <el-date-picker
            v-model="dealtime"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
            style="width: 80%"
          >
          </el-date-picker>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="measureReq">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="确定验证"
      :visible.sync="dialogVisible3"
      width="30%"
      style="margin-top: 50px"
      >
      <div class="confirm-verification" style="padding-left: 30px">
        <div class="dialog-input">
          <span class="span">验证情况：</span>
          <el-input
            type="textarea"
            :rows="2"
            v-model="provenContent"
            placeholder="请输入"
          ></el-input>
        </div>
        <div class="dialog-input">
          <span>是否通过：</span>
          <el-select v-model="isPass" placeholder="请选择是或否">
            <el-option
              v-for="item in ['是', '否']"
              :key="item"
              :label="item"
              :value="item"
              @change="bindIsPassInp"
            >
            </el-option>
          </el-select>
        </div>
        <div class="dialog-input">
          <span>现场证据：</span>
          <div class="p_pic">
            <div class="imgall" style="justify-content: start">
              <!-- <template v-for="item in imgList">
                <div :key="item.id" class="ImgList">
                  <div>
                    <img class="haveImg" :src="item.picNameAndAddr" />
                    <span class="span_delete" @click="deleteImg(item.id)"
                      >删除</span
                    >
                  </div>
                </div>
              </template> -->
              <span class="ImgDel" v-for="(item,index) in srcList" :key="item">
                <el-image
                  style="width: 100px; height: 100px"
                  :src="item"
                  :preview-src-list="srcList"
                  @mouseover="overimg(index)"
                >
                </el-image>

                <i
                  class="el-icon-delete-solid"
                  v-show="index === Curindex"
                  @click="deleteImg(item)"
                ></i>
              </span>
              <el-upload
                action="https://jsonplaceholder.typicode.com/posts/"
                list-type="picture-card"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :http-request="uploadFile"
                class="avatar-uploader"
                ref="upload"
                :headers="token"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="passReq">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="证据照片"
      :visible.sync="dialogVisible4"
      width="25%"
      style="margin-top: 50px"
      >
      <div v-if="imgList.length != 0">
        <!-- <template>
          <div class="ImgList2">
            <div v-for="item in imgList" :key="item.id">
              <img :src="item.picNameAndAddr" />
            </div>
          </div>
        </template> -->
        <span class="ImgDel" v-for="(item) in srcList" :key="item">
        <el-image
          style="width: 100px; height: 100px"
          :src="item"
          :preview-src-list="srcList"
        >
        </el-image>
      </span>
      </div>
      <p v-if="imgList.length == 0" class="imgP">空空如也~</p>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod, baseURL } from "../../api/http";
import { compressImage } from "../../modules/CompressImage";
import imgTypeChange from "../../modules/imgChange";
export default {
  data() {
    return {
      userNo: this.$store.state.userInfo.userNo,
      userName: this.$store.state.userInfo.userName,
      elementHeight: 0,
      tabIndex: 0,
      tabList: [
        "待发出报告",
        "待提交措施",
        "待提交验证",
        "待确定验证",
        "已通过验证",
      ],
      startTime: "",
      endTime: "",
      tableData: [],
      tableData2: [],
      unqualifiedList1: [],
      unqualifiedList2: [],
      unqualifiedList3: [],
      unqualifiedList4: [],
      unqualifiedList5: [],
      currentPage: 1,
      curSize: 10,
      start: 0,
      end: 10,
      tabBtnIndex: 0,
      tableWidth: 600,
      dialogVisible: false, // 发出报告
      describe: "",
      listItem: {},
      measures: "", //提交措施
      dealtime: "",
      dialogVisible2: false,
      dialogVisible3: false, //确定验证
      provenContent: "",
      isPass: "",
      imgList: [], // 图片
      srcList:[],
      ImgFile: [],
      fileList: [],
      dialogVisible4: false,
      imgVisible: false,
      token: {},
      baseUrl: "",
      imgUrl: "",
      Curindex: "",
    };
  },
  filters: {
    time: function (e) {
      if (e === null) {
        return "";
      }
      return e.split("T")[0];
    },
  },
  mounted() {
    this.getElementHeight();
    this.clickBtnIndex(0);
    this.token = {
      Authorization:
        "Bearer " + JSON.parse(window.sessionStorage.getItem("token")),
    };
    this.baseUrl = baseURL[process.env.NODE_ENV + ""];
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 70 + 150); //70是div底部距离底部的高度
      });
    },
    search(){
      this.startTime = this.startTime == null? '' : this.startTime;
      this.endTime = this.endTime == null? '' : this.endTime;
      this.clickBtnIndex(this.tabBtnIndex);
    },
    //获取检查列表(所有请求顺序：现场/文件)
    async clickBtnIndex(i) {
      this.tabBtnIndex = i;
      this.tableData = [];
      this.unqualifiedList1 = [];
      this.unqualifiedList2 = [];
      this.unqualifiedList3 = [];
      this.unqualifiedList4 = [];
      this.unqualifiedList5 = [];
      var url = "";
      switch (i) {
        case 0:
          url = "/api/InapAreaTrack";
          break;
        case 1:
          url = "/api/InapFileTrack";
          break;
        case 2:
          url = "/api/InapExminerulesTrack";
          break;
        case 3:
          url = "/api/InapInoutTrack";
          break;
        case 4:
          url = "/api/SupplierUnqualified/GetAll";
          break;
      }
      await get(
        url + "?fromDate=" + this.startTime + "&toDate=" + this.endTime
      ).then((resp) => {
        if (resp.code == 200) {
          resp.data.map((item) => {
            if (item.trackState == 0) {
              this.unqualifiedList1.push(item);
            } else if (item.trackState == 1) {
              this.unqualifiedList2.push(item);
              this.unqualifiedList2.forEach((item) => {
                item.sendTrackDate = item.sendTrackDate.split("T")[0]
              })
              this.unqualifiedList2.sort((a, b) => new Date(a.sendTrackDate) - new Date(b.sendTrackDate));  
            } else if (item.trackState == 2) {
              this.unqualifiedList3.push(item);
            } else if (item.trackState == 3) {
              this.unqualifiedList4.push(item);
            } else {
              this.unqualifiedList5.push(item);
            }
          });
          this.clickTab(this.tabIndex);
          console.log(this.unqualifiedList2);
        }
      });
    },
    clickTab(i) {
      this.tabIndex = i;
      this.tableWidth = i != 0 ? 300 : 600;
      this.start = 0;
      this.end = 10;
      this.curSize = 10;
      this.currentPage = 1;
      if (i == 0) {
        this.tableData = this.unqualifiedList1;
      } else if (i == 1) {
        this.tableData = this.unqualifiedList2;
      } else if (i == 2) {
        this.tableData = this.unqualifiedList3;
      } else if (i == 3) {
        this.tableData = this.unqualifiedList4;
      } else {
        this.tableData = this.unqualifiedList5;
      }
    },

    //  发出报告
    toReport(row) {
      this.dialogVisible = true;
      this.listItem = row;
    },
    async allToReport() {
      //全部发出
      if (this.tableData.length == 0) return;
      var url = "";
      switch (this.tabBtnIndex) {
        case 0:
          url = "/api/InapAreaTrack/PutOneAll";
          break;
        case 1:
          url = "/api/InapFileTrack/PutOneAll";
          break;
        case 2:
          url = "/api/InapExminerulesTrack/PutOneAll";
          break;
        case 3:
          url = "/api/InapInoutTrack/PutOneAll";
          break;
        case 4:
          url = "/api/SupplierUnqualified/PutOneAll";
          break;
      }
      var data = [];
      this.tableData.map((i) => {
        data.push({
          id: i.id,
          CheckRecord: i.checkRecord,
          UserUno: this.$store.state.userInfo.userNo,
          UserName: this.$store.state.userInfo.userName,
          Type: i.type,
        });
      });
      await put(url, { data: JSON.stringify(data) })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "全部发出成功",
              type: "success",
            });
            this.clickBtnIndex(this.tabBtnIndex);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("全部发出失败");
        });
    },
    reportReq() {
      //单个发出
      var url = "";
      switch (this.tabBtnIndex) {
        case 0:
          url = "/api/InapAreaTrack/PutOne";
          break;
        case 1:
          url = "/api/InapFileTrack/PutOne";
          break;
        case 2:
          url = "/api/InapExminerulesTrack/PutOne";
          break;
        case 3:
          url = "/api/InapInoutTrack/PutOne";
          break;
        case 4:
          url = "/api/SupplierUnqualified/PutOne";
          break;
      }
      put(
        url +
          "?id=" +
          this.listItem.id +
          "&checkRecord=" +
          this.describe +
          "&UserUno=" +
          this.userNo +
          "&UserName=" +
          this.userName +
          "&Type=" +
          this.listItem.type
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "发送成功",
              type: "success",
            });
            this.dialogVisible = false;
            this.describe = "";
            this.clickBtnIndex(this.tabBtnIndex);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("发送失败");
        });
    },
    // 提交措施
    // bindDealtimeInp(e) {
    //   console.log(e);
    //   this.dealtime = e.toISOString();
    // },
    // 提交措施
    submitMeasure(row) {
      this.dialogVisible2 = true;
      this.measures = "";
      this.dealtime = "";
      this.listItem = row;
    },
    measureReq() {
      var url = "";
      switch (this.tabBtnIndex) {
        case 0:
          url = "/api/InapAreaTrack/PutTwo";
          break;
        case 1:
          url = "/api/InapFileTrack/PutTwo";
          break;
        case 2:
          url = "/api/InapExminerulesTrack/PutTwo";
          break;
        case 3:
          url = "/api/InapInoutTrack/PutTwo";
          break;
        case 4:
          url = "/api/SupplierUnqualified/PutTwo";
          break;
      }
      put(
        url +
          "?id=" +
          this.listItem.id +
          "&Measures=" +
          this.measures +
          "&Dealtime=" +
          this.dealtime +
          "&UserUno=" +
          this.userNo +
          "&UserName=" +
          this.userName +
          "&Type=" +
          this.listItem.type
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "提交成功",
              type: "success",
            });
            this.start = 0;
            this.currentPage = 1;
            this.clickBtnIndex(this.tabBtnIndex);
            this.dialogVisible2 = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("提交失败");
        });
    },
    // 提交验证
    reqExamine(row) {
      var url = "";
      switch (this.tabBtnIndex) {
        case 0:
          url = "/api/InapAreaTrack/PutThere";
          break;
        case 1:
          url = "/api/InapFileTrack/PutThere";
          break;
        case 2:
          url = "/api/InapExminerulesTrack/PutThere";
          break;
        case 3:
          url = "/api/InapInoutTrack/PutThere";
          break;
        case 4:
          url = "/api/SupplierUnqualified/PutThere";
          break;
      }
      put(
        url +
          "?id=" +
          row.id +
          "&UserUno=" +
          this.userNo +
          "&UserName=" +
          this.userName +
          "&Type=" +
          row.type
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "验证成功",
              type: "success",
            });
            this.clickBtnIndex(this.tabBtnIndex);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("验证失败");
        });
    },
    // 确定验证
    bindIsPassInp(e) {
      this.isPass = e;
    },
    confirmExamine(row) {
      this.listItem = row;
      this.dialogVisible3 = true;
      this.isPass = "";
      this.provenContent = "";
      this.handleUrl();
      this.reqGetPic();
    },
    passReq() {
      if (!this.provenContent) {
        this.$message({
          message: "请填写验证情况",
          type: "warning",
        });
        return;
      }
      if (this.isPass == "") {
        this.$message({
          message: "请填写是否通过验证",
          type: "warning",
        });
        return;
      }
      var url = "";
      switch (this.tabBtnIndex) {
        case 0:
          url = "/api/InapAreaTrack/PutFour";
          break;
        case 1:
          url = "/api/InapFileTrack/PutFour";
          break;
        case 2:
          url = "/api/InapExminerulesTrack/PutFour";
          break;
        case 3:
          url = "/api/InapInoutTrack/PutFour";
          break;
        case 4:
          url = "/api/SupplierUnqualified/PutFour";
          break;
      }
      put(
        url +
          "?id=" +
          this.listItem.id +
          "&Content=" +
          this.provenContent +
          "&isOk=" +
          (this.isPass = this.isPass == "是" ? true : false) +
          "&UserUno=" +
          this.userNo +
          "&UserName=" +
          this.userName +
          "&Type=" +
          this.listItem.type
      )
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "验证成功",
              type: "success",
            });
            this.clickBtnIndex(this.tabBtnIndex);
            this.dialogVisible3 = false;
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("验证失败");
        });
    },
    // 上传图片
    handleUrl() {
      switch (this.tabBtnIndex) {
        case 0:
          this.imgUrl = "/api/InadAreaTrackpic";
          break;
        case 1:
          this.imgUrl = "/api/InadFileTrackpic";
          break;
        case 2:
          this.imgUrl = "/api/InadExminerulesTrackpic";
          break;
        case 3:
          this.imgUrl = "/api/InadInoutTrackpic";
          break;
        case 4:
          this.imgUrl = "/api/SupplierUnqualifiedpic";
          break;
      }
    },
    reqGetPic() {
      this.srcList = [];
      get(
        this.imgUrl + "?id=" + this.listItem.id + "&Type=" + this.listItem.type
      ).then((resp) => {
        if (resp.code == 200) {
          this.imgList = resp.value;
          resp.value.forEach((element) => {
            this.srcList.push(element.picNameAndAddr);
          });
        }
      });
    },
    overimg(index) {
      this.Curindex = index;
    },
    handleRemove(file) {
      var nIndex = this.ImgFile.findIndex((e) => e.oldName == file.name);
      this.ImgFile.splice(nIndex);
    },
    handlePictureCardPreview() {},
    async uploadFile(e) {
      if (e.file) {
        let file = await imgTypeChange(e.file);
        let newfile = await compressImage(file);
        if (newfile.size > 10000) {
          newfile = await compressImage(newfile);
        }
        this.uploadFileReq(newfile);
      }
    },
    uploadFileReq(file) {
      var formData = new FormData();
      formData.append("files", file);
      post("/api/UpLoadFiles/UploadTrackImg", formData)
        .then((resp) => {
          this.$refs.upload.clearFiles();
          if (resp.code == 200) {
            var data = {
              Id: 0,
              PicName: this.baseUrl + "/uploadTracks/" + resp.value,
              PicDescription: "",
              RelationId: this.listItem.id,
              SaveUno: this.userNo,
              SaveName: this.userName,
            };
            this.submitAddPic(data);
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$refs.upload.clearFiles();
          this.$message.error("上传失败");
        });
    },
    submitAddPic(data) {
      post(this.imgUrl + "?Type=" + this.listItem.type, {
        data: JSON.stringify([data]),
      })
        .then((resp) => {
          if (resp.code == 200) {
            this.$message({
              message: "上传成功",
              type: "success",
            });
            this.$refs.upload.clearFiles();
            this.reqGetPic();
          } else {
            this.$message.error(resp.message);
          }
        })
        .catch(() => {
          this.$message.error("上传失败");
        });
    },
    // 删除图片
    deleteImg(item) {
      // this.$confirm("确定删除该图片?", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning",
      // }).then(() => {
      //   deleteMethod(this.imgUrl, { Id: id, Type: this.listItem.type })
      //     .then((res) => {
      //       if (res.code == 200) {
      //         this.$message({
      //           message: "删除成功",
      //           type: "success",
      //         });
      //         this.reqGetPic();
      //       } else {
      //         this.$message.error(res.message);
      //       }
      //     })
      //     .catch(() => {
      //       this.$message.error("删除失败");
      //     });
      // });
      this.imgList.forEach((e) => {
        if (e.picNameAndAddr === item) {
          this.$confirm("此操作将永久删除该图片", "是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              deleteMethod(this.imgUrl,{ Id: e.id, Type: this.listItem.type }).then(() => {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.reqGetPic();
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
        }
      });
    },
    // 查看图片
    clickImageBtn(row) {
      this.dialogVisible4 = true;
      this.listItem = row;
      this.handleUrl();
      this.reqGetPic();
    },
    // 分页
    handleSizeChange(e) {
      this.curSize = e;
      this.start = (this.currentPage - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
    handleCurrentChange(e) {
      this.currentPage = e;
      this.start = (e - 1) * this.curSize;
      this.end = this.start + this.curSize;
    },
  },
};
</script>

<style lang="less" scoped>
.tab-header {
  display: flex;
  align-content: center;
  .text {
    margin-left: 20px;
  }
}
._Tab {
  color: #a09f9f;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

._Tab .activeColor {
  border-bottom: 2px solid #2f98ff;
  color: black;
}

._Tab span {
  padding: 10px 0;
}
.tab-btn {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 20px;
}
.primary {
  background-color: #1361FF !important;
  color: white;
}
.unqualified span {
  padding-bottom: 5px;
  display: inline-block;
}
.measures-dialog .block {
  margin-bottom: 10px;
}
.confirm-verification .el-textarea {
  width: 75% !important;
}
/deep/ .confirm-verification .el-upload--picture-card {
  width: 65px !important;
  height: 65px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  margin-top: 3px;
}
/deep/ .confirm-verification .el-icon-plus {
  font-size: 16px !important;
}
.confirm-verification .el-input {
  width: auto !important;
}
.confirm-verification .dialog-input {
  display: flex;
  align-content: center;
  margin-bottom: 10px;
}
.confirm-verification .dialog-input .span {
  width: auto !important;
  text-align: end;
}
.ImgList {
  float: left;
  margin-left: 3px;
}
.ImgList div {
  float: left;
  width: 65px;
  position: relative;
}
.ImgList img {
  width: 100%;
  height: 65px;
  float: left;
}
.imgall {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 65px;
  height: 65px;
  line-height: 65px;
}
.span_delete {
  position: absolute;
  display: block;
  background: red;
  color: #ffffff;
  bottom: 0;
  width: 100% !important;
  text-align: center;
}
.p_pic {
  margin-top: 5px;
  width: 270px;
}
.ImgList2 {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
}
.ImgList2 img {
  width: 80px;
  height: 80px;
}
.imgP {
  text-align: center;
  margin-bottom: 20px;
}
.ImgDel {
  position: relative;
}

.ImgDel i {
  transform: scale(1.5);
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

::v-deep .confirm-verification .el-upload--picture-card{
  width: 100px !important;
  height: 100px !important;
}

</style>